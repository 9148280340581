<script lang="ts">
    import { getContext, onMount } from 'svelte';
    import { Card, CardContent } from '$lib/components/ui/card';
    import { Switch } from '$lib/components/ui/switch';
    import { Label } from '$lib/components/ui/label';
    import * as Select from '$lib/components/ui/select';
    import { Button } from '$lib/components/ui/button';
    import axios from 'axios';

    interface AuthUser {
        public_profile: boolean;
        open_profile: boolean;
        enable_geoblocking: boolean;
        geoblocked_countries: string[];
    }

    interface Country {
        name: string;
    }

    interface SettingsData {
        allow_users_enabling_open_profiles: boolean;
        allow_geo_blocking: boolean;
        countries: Country[];
    }

    interface SettingsContext {
        authUser: AuthUser;
        data: SettingsData;
    }

    const settingsContext = getContext<SettingsContext>('settingsContext');
    const { authUser, data } = settingsContext;

    let selectedCountries = authUser.geoblocked_countries || [];

    async function updateFlagSetting(key: string, value: boolean) {
        try {
            await axios.post('/settings/update-flag', { key, value });
            authUser[key] = value;
            launchToast('success', 'Success', 'Setting updated successfully');
        } catch (error) {
            launchToast('danger', 'Error', 'Failed to update setting');
        }
    }

    async function updateGeoBlockedCountries(countries: string[]) {
        try {
            await axios.post('/settings/update-geoblocked-countries', { countries });
            authUser.geoblocked_countries = countries;
            launchToast('success', 'Success', 'Geo-blocked countries updated');
        } catch (error) {
            launchToast('danger', 'Error', 'Failed to update geo-blocked countries');
        }
    }

    let deviceToBeDeleted: string | null = null;
    let showDeleteDialog = false;

    function showDeviceDeleteConfirmation(signature: string) {
        deviceToBeDeleted = signature;
        showDeleteDialog = true;
    }

    async function removeDevice() {
        if (!deviceToBeDeleted) return;

        try {
            const response = await axios.delete('/device-verify/delete', {
                data: { signature: deviceToBeDeleted },
            });
            if (response.data.success) {
                launchToast('success', 'Success', 'Device removed successfully');
                // Implement reload functionality or update the device list
            } else {
                launchToast('danger', 'Error', response.data.errors[0]);
            }
        } catch (error) {
            launchToast('danger', 'Error', 'Failed to remove device');
        }
        showDeleteDialog = false;
        deviceToBeDeleted = null;
    }

    onMount(() => {
        //
    });
</script>

<div class="space-y-4">
    <Card>
        <CardContent class="pt-6">
            <div class="flex items-center space-x-2">
                <Switch
                    id="public_profile"
                    checked="{authUser.public_profile}"
                    on:change="{() => updateFlagSetting('public_profile', !authUser.public_profile)}"
                />
                <Label for="public_profile">Is public account</Label>
            </div>
            <div class="mt-2">
                <span>Having your profile set to private means:</span>
                <ul class="mt-1 list-disc space-y-1 pl-5">
                    <li>It will be hidden for search engines and unregistered users entirely.</li>
                    <li>It will also be generally hidden from suggestions and user searches on our platform.</li>
                </ul>
            </div>
        </CardContent>
    </Card>

    {#if data.allow_users_enabling_open_profiles}
        <Card>
            <CardContent class="pt-6">
                <div class="flex items-center space-x-2">
                    <Switch
                        id="open_profile"
                        checked="{authUser.open_profile}"
                        on:change="{() => updateFlagSetting('open_profile', !authUser.open_profile)}"
                    />
                    <Label for="open_profile">Open profile</Label>
                </div>
                <div class="mt-2">
                    <span>Having your profile set to open means:</span>
                    <ul class="mt-1 list-disc space-y-1 pl-5">
                        <li>Both registered and unregistered users will be able to see your posts.</li>
                        <li>If account is private, the content will only be available for registered used.</li>
                        <li>Paid posts will still be locked for open profiles.</li>
                    </ul>
                </div>
            </CardContent>
        </Card>
    {/if}

    {#if data.allow_geo_blocking}
        <Card>
            <CardContent class="pt-6">
                <div class="flex items-center space-x-2">
                    <Switch
                        id="enable_geoblocking"
                        checked="{authUser.enable_geoblocking}"
                        on:change="{() => updateFlagSetting('enable_geoblocking', !authUser.enable_geoblocking)}"
                    />
                    <Label for="enable_geoblocking">Enable Geo-blocking</Label>
                </div>
                <div class="mt-2">
                    <small class="text-sm text-gray-500">
                        If enabled, visitors from certain countries will be restricted access.
                    </small>
                </div>
                <div class="mt-4">
                    <Label for="countrySelect">Country</Label>
                    <Select.Root
                        multiple
                        bind:value="{selectedCountries}"
                        on:change="{() => updateGeoBlockedCountries(selectedCountries)}"
                    >
                        <Select.Trigger class="w-full">
                            <Select.Value placeholder="Select countries" />
                        </Select.Trigger>
                        <Select.Content>
                            {#each data.countries as country}
                                <Select.Item value="{country.name}">{country.name}</Select.Item>
                            {/each}
                        </Select.Content>
                    </Select.Root>
                </div>
            </CardContent>
        </Card>
    {/if}
</div>

{#if showDeleteDialog}
    <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="rounded-lg bg-white p-6">
            <h2 class="mb-4 text-lg font-bold">Confirm Device Deletion</h2>
            <p>Are you sure you want to delete this device?</p>
            <div class="mt-4 flex justify-end space-x-2">
                <Button on:click="{() => (showDeleteDialog = false)}">Cancel</Button>
                <Button variant="destructive" on:click="{removeDevice}">Delete</Button>
            </div>
        </div>
    </div>
{/if}
